function ConfirmationModal(props) {
    let paymentText;
    if (props.paymentData && props.paymentData.paymentMethod === 'mb'){
      paymentText = 
      <div>
        <h4 className="text-center text-secondary title-padding title-mobile">
          Pagamento por referência multibanco:
        </h4>
        <p style={{margin: '5px', fontSize: '16px'}}>Entidade: {props.paymentData.entity}</p>
        <p style={{margin: '5px', fontSize: '16px'}}>Referência: {props.paymentData.reference}</p>
        <p style={{margin: '5px', fontSize: '16px'}}>Valor: {props.paymentData.value}€</p>
      </div> 
    }
    else if (props.paymentData && props.paymentData.paymentMethod === 'mbw'){
      paymentText = 
      <div>
        <h4 className="text-center text-secondary title-padding title-mobile">
          Pagamento por MBWAY:
        </h4>
        <p style={{margin: '5px', fontSize: '16 px'}}>Vai à tua aplicação MBWAY e aceita o pagamento nos próximos 5 min!</p>
      </div>
    }

    return (
      <section className="popup popup-with-backcolor" id="accept">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{textAlign: 'center'}}>
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.setConfirmationMessage(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 className="text-center title-padding title-mobile">
              Inscrição Concluída
            </h3>
            {paymentText}
            <p style={{fontSize: '16px'}}>
            {props.paymentData && props.paymentData.paymentMethod === 'mb' && 'Enviámos-te um e-mail de confirmação com informações relativas ao pagamento.'}
            {props.paymentData && props.paymentData.paymentMethod === 'mbw' && 'Enviámos-te um e-mail de confirmação com informações relativas à tua inscrição.'}
            </p>
          </div>
        </div>
      </section>
    );
}
  
export default ConfirmationModal;
