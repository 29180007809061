import Footer from "../components/footer";
import {useEffect, useState} from "react";
import { useHistory } from "react-router";
import {db} from "../utils/firebaseUtils";
import { query } from "firebase/firestore";
import * as XLSX from 'xlsx';

function Participants() {

  const [formations, setFormations] = useState([]);
  const formURL = decodeURIComponent(window.location.pathname.substring(14));
  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    const getFormations = async () => {
      const formationRef = db.collection('formations');

      const snapshot = await formationRef.where('url','==', formURL).get();
      setFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    getFormations();
  }, []);
  useEffect(() => {
    const getParticipants = async () => {
      let idValue = 0;
      if (formations[0] !== undefined) {
        idValue = formations[0].id;
      }
      const registrationRef = db.collection('formations/' + idValue + '/enrolled').orderBy("registrationExpiration", "desc");
      registrationRef.get().then((querySnapshot) => {
        const updatedParticipants = [];
        querySnapshot.forEach((doc) => {
          updatedParticipants.push({ id: doc.id, ...doc.data() });
        });
        setParticipants(updatedParticipants);
      });
    }
    formations !== [] && getParticipants()
  }, [formations]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(participants);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    XLSX.writeFile(workbook, 'Participantes ' + formations[0].name + '.xlsx');    
  };
  return (
    <>
      <div className="container context gradient-container" id="backoffice-page-participants">
         <h2 className="title">Participantes</h2>
          <div className=" justify-content-center g-0">
                   {/* {formations[0] !== undefined && <h4>Número de participantes pagos: { formations[0].numberOfSpots}</h4>} */}
          <button className="button-participantes" onClick={exportToExcel}>Exportar para Excel</button>
          <div className="row justify-content-center mb-5 table-responsive tablediv">
            <table className="table" id="participantsTable">
              <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Nº UP</th>
                <th scope="col">Email</th>
                <th scope="col">Nº Tel.</th>
                <th scope="col">Pago</th>
                {/* <th scope="col">Data Exp</th> */}
                {/* <th scope="col">ID de Transf</th> */}
                <th scope="col">Data Limite Pagamento</th>
                <th scope="col">CC</th>
                <th scope="col">Validade CC</th>
                <th scope="col">Data de Nascimento</th>
                <th scope="col">Curso</th>
                <th scope="col">Ano</th>
              </tr>
              </thead>
              <tbody>
                {
                  participants !== [] && participants.map((part) => {
                    const date = part.registrationExpiration ? new Date(part.registrationExpiration) : null
                    return(
                      <tr key={part.id}>
                        <td>{part.fullName}</td>
                        <td>{part.studentNumber}</td>
                        <td>{part.email}</td>
                        <td>{part.phoneNumber}</td>
                        <td>{part.paid ? 
                          <i className="bi bi-check-square-fill" style={{color: '#0bda51', width:40, height:40}} title="Pago"></i> :
                          <i className="bi bi-x-square-fill" style={{color: '#ee4b2b', width:40, height:40}}title="Não Pago"></i>
                  }</td>
                        <td>{date ? (date.toISOString().substring(0,10) + ' ' + date.toISOString().substring(11, 19)) : 'ERRO'}</td>
                        <td>{part.idCardNumber}</td>
                        <td>{part.idCardExpirationDate}</td>
                        <td>{part.birthDate}</td>
                        <td>{part.course}</td>
                        <td>{part.year}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Participants;
